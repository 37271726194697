<template>
  <NuxtLinkLocale :to="link" :target="isUrlExternal(link) ? '_blank' : null" :aria-label="ariaLabel">
    <slot />
  </NuxtLinkLocale>
</template>

<script setup>
const { isUrlExternal } = useUrl()

defineProps({
  link: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
})
</script>
